import React from 'react';
import Loadable from 'react-loadable';
import { graphql } from 'gatsby';
import {
  ColorDataTableDesign,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import levelsPreviewData from '../../../../data/previews/levels.yml';
import pageHeroData from '../../../../data/pages/guidelines.yml';
import styles from './styles/level.module.scss';

const Level = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Level;
    return <Component {...props} />;
  },
});

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Themes"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Themes" />

      <PageNavigation links={['Options', 'Usage', 'Values']} />

      <Section title="Options">
        <Section>
          <Paragraph>
            <Link href="/components/themes/environment" isDesignCodeLink>
              Environments
            </Link>{' '}
            provide the color scheme for a given activity.
          </Paragraph>

          <img
            src={require('../../../../imgs/diagrams/environment-light-vs-dark.png')}
            className="uni-padding--one--bottom"
          />

          <List type="unordered">
            <li>
              <strong>Light Environment</strong> (LE) — Our default environment
              fits browsing experiences for everyday work, such as viewing or
              managing information.
            </li>
            <li>
              <strong>Dark Environment</strong> (DE) — For immersive content
              experiences, such as video editing or tagging.
            </li>
          </List>
        </Section>
        <Section>
          <Paragraph className="uni-padding--one-and-half--top uni-padding--one--bottom">
            <Link href="/components/themes/level" isDesignCodeLink>
              Levels
            </Link>{' '}
            apply a color system to group elements within the interface.
          </Paragraph>

          <Paragraph>Use level base colors to segment a layout.</Paragraph>

          <ComponentPreview
            name="ThemesBases"
            layout="default"
            previewData={levelsPreviewData.bases}>
            <Level className={styles.basePreviewWrapper}>
              <div className={styles.basePreview}>Base</div>
            </Level>
          </ComponentPreview>

          <Paragraph>
            Use the accent color only on its respective base color.
          </Paragraph>

          <ComponentPreview
            name="ThemesAccents"
            layout="default"
            previewData={levelsPreviewData.accents}>
            <Level type="base" className={styles.accentsBasePreviewWrapper}>
              <div className={styles.accentsBasePreview}>Base</div>
            </Level>
            <Level type="accent" className={styles.accentsAccentPreviewWrapper}>
              <div className={styles.accentsAccentPreview}>Accent</div>
            </Level>
          </ComponentPreview>
        </Section>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Video Players</SectionSubhead>
        <Paragraph>
          Refrain from using level colors in video players. Our base colors are
          better suited for that.
        </Paragraph>
        <DontDo
          dontText="use nontraditional colors for video players."
          doText="use base colors for a cohesive experience."
          imgFilename="colors-themes-video"
        />
      </Section>

      <Section title="Values">
        <Section>
          <SectionSubhead>Light Environment</SectionSubhead>
          <ColorDataTableDesign data={data} colorsType="light" />
        </Section>

        <Section>
          <SectionSubhead>Dark Environment</SectionSubhead>
          <ColorDataTableDesign data={data} env="dark" colorsType="dark" />
        </Section>

        <Section>
          <SectionSubhead>Base</SectionSubhead>
          <ColorDataTableDesign data={data} />
        </Section>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query environmentColorsDesign($type: String = "/Base|Theme|Divider/") {
    ...colorDataDesign
  }
`;
